import React, { useContext } from "react";
import styled from "styled-components";
import HeroTitles from "../shared/heroTitles";
import Lists from "../shared/customLists";
import { StaticImage } from "gatsby-plugin-image";
import SpecialCase from "../shared/container";
import loadable from "@loadable/component";
import { useIntl } from "gatsby-plugin-react-intl";
import CustomDownloadButton from "../shared/CustomDownloadButton";
import { redDownloadBtn } from "../../utils/CustomDownloadBtnStyles";
import { GlobalContext } from "../../Contexts/GlobalContext";

const BackgroundImg = loadable(
  () => {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve(
            import(
              "../../images/indexPageImages/heroImages/hero_background.svg"
            )
          ),
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
          ? 3000
          : 1
      );
    });
  },
  { fallback: <div className="fallback"></div> }
);

console.log("test", BackgroundImg);

const ContainerComponent = styled.div`
  display: grid;
  grid-area: 1/1;
  height: 650px;
  place-items: center;
  width: 100%;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background: #f5f5f8;
  overflow: hidden;

  @media (max-width: 960px) {
    height: max-content;
  }

  .background-img {
    z-index: -1;
    grid-area: 1/1;
  }

  .fallback {
    width: 100%;
    height: 100%;
    grid-area: 1/1;
    z-index: -2;
    background-color: #f2f2f2;
  }

  .fallback-recovery {
    width: 100%;
    height: 100%;
    grid-area: 1/1;
    z-index: -3;
    background-color: #f2f2f2;
  }
`;

const HeroComponent = styled.section`
  display: flex;
  height: 650px;
  width: 100%;
  @media (max-width: 960px) {
    height: max-content;
    flex-direction: column;
    gap: 49px;
    padding-bottom: 46.5px;
  }
  //special CSS only for StaticImage
  .hero-img {
    height: auto;
    /* width: 534px; */
    margin: 0 auto;

    @media (max-width: 375px) {
      width: 300px;
      align-self: flex-start;
    }
  }

  .download-trial {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    width: 252px;
    height: 51px;
    background: #3571ce;
    border-radius: 60px;
    color: #ffffff;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.6px;

    &:hover {
      background: rgba(53, 113, 206, 0.8);
    }
  }
`;

const Span = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 58px;
  color: #2c6491;

  @media (max-width: 960px) {
    font-size: 52px;
    line-height: 58px;
  }

  @media (max-width: 475px) {
    font-size: 32px;
    line-height: 42px;
  }
`;

const Box = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* overflow: hidden; */
  img {
    width: 580px;
    height: auto;
    margin: auto;
  }
  .gatsby-image-wrapper {
    overflow: visible;
  }
  img {
    @media (min-width: 960px) AND (max-width: 1260px) {
      padding-left: 15%;
      width: 667px;
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  @media (max-width: 960px) {
    justify-self: center;
    align-self: center;
    width: 80%;
  }
  @media (max-width: 475px) {
    /* align-self: flex-start; */
    /* margin-left: 5%; */
  }

  :nth-child(2) {
    align-self: center;
  }
`;

const ParagraphGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 499px;
  height: auto;
  margin: 40px 0px;
  margin-top: 0;

  @media (max-width: 960px) {
    width: 100%;
    height: max-content;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    margin: 0px;
    @media (max-width: 960px) {
      width: 100%;
      margin-left: 5%;
    }
  }
`;

const SpecialGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;
  height: fit-content;
  align-items: flex-start;

  @media (max-width: 960px) {
    width: 100%;
    height: max-content;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 0px;
    margin: 0px;
    @media (max-width: 960px) {
      width: 100%;
      margin-left: 5%;
    }
  }
`;

const SpecialInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-top: 20px;
`;

const Info = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

const Holder = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnderTitle = styled.h2`
  font-size: 32px;
  font-weight: 300;
  max-width: 583px;
`;

function Hero() {
  // const intl = useIntl();
  const intl = useIntl();
  const { isMac, setMacOpen, setLinkType } = useContext(GlobalContext);

  return (
    <ContainerComponent id="boss-element">
      <div className="fallback-recovery"></div>
      {/* {typeof window !== "undefined" && (
        <BackgroundImg className="background-img" />
      )} */}
      <SpecialCase className="special-class">
        <HeroComponent>
          <Box>
            <HeroTitles>
              <Span>PDF </Span> Creator
            </HeroTitles>
            <UnderTitle>
              {intl.formatMessage({
                id: "HeroUnderTitle",
              })}
            </UnderTitle>
            <ParagraphGroup>
              <ul>
                <Lists>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HeroBulletPointOne",
                      }),
                    }}
                  />
                </Lists>
                <Lists>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HeroBulletPointTwo",
                      }),
                    }}
                  />
                </Lists>
                <Lists>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HeroBulletPointThree",
                      }),
                    }}
                  />{" "}
                </Lists>
                <Lists>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HeroBulletPointFour",
                      }),
                    }}
                  />
                </Lists>
                <Lists>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "HeroBulletPointFive",
                      }),
                    }}
                  />
                </Lists>
              </ul>
            </ParagraphGroup>
            <SpecialGroup id="do-it">
              <Holder>
                {!isMac ? (
                  <CustomDownloadButton
                    title={intl.formatMessage({ id: "HeroDownload" })}
                    navigation="https://track.pdfpro10.com/product/normal/pdfcreator/trial"
                    tracker="tracker"
                    styles={redDownloadBtn}
                  />
                ) : (
                  <CustomDownloadButton
                    title={intl.formatMessage({ id: "HeroDownload" })}
                    navigation={"/"}
                    styles={redDownloadBtn}
                    onClick={() => {
                      setMacOpen(true);
                      setLinkType("trial");
                    }}
                  />
                )}
                <SpecialInfo>
                  <Info>
                    {intl.formatMessage({
                      id: "FileSize",
                    })}
                    : 15mb
                  </Info>
                  <Info>
                    {intl.formatMessage({
                      id: "CompatibleWithWindows",
                    })}
                  </Info>
                </SpecialInfo>
              </Holder>
            </SpecialGroup>
          </Box>
          <Box>
            <StaticImage
              src="../../images/indexPageImages/heroImages/hero_img.png"
              quality={95}
              formats={["AUTO", "WEBP", "AVIF", "PNG"]}
              alt="Picture of PDF creator product"
              className="hero-img"
            />
          </Box>
        </HeroComponent>
      </SpecialCase>
    </ContainerComponent>
  );
}

export default Hero;
