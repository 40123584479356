import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/homepage/hero";
import Read from "../components/homepage/read";
import Create from "../components/homepage/create";
import Client from "../components/homepage/client";
import Choose from "../components/homepage/choose";
import Ready from "../components/homepage/ready";
import Requirements from "../components/homepage/requirements";
import {
  useIntl,
  injectIntl,
  Link,
  FormattedMessage,
} from "gatsby-plugin-react-intl";
import Layout from "../components/shared/layout";

const IndexPage = ({ location }) => {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "LandingPageTitle",
        })}
        description={intl.formatMessage({
          id: "LandingPageDescription",
        })}
      />
      <Hero location={location} />
      <Read />
      {/* <Create /> */}
      <Client />
      <Choose />
      <Requirements />
      <Ready />
    </Layout>
  );
};

export default IndexPage;
