import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BarTop = styled.div`
  width: 100%;
  min-height: 47px;
  background: #e6e6e6;
`;

const BarBottom = styled.div`
  width: 100%;
  height: auto;
  background: rgb(144, 144, 144);
  padding: 56px;
`;

const BarBottomText = styled.div`
  max-width: 1440px;
  margin: auto;
  text-align: center;
  color: #d9d9d9;
`;

const NavBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 1440px;
  margin: auto;

  @media (max-width: 1110px) {
    display: flex;
    flex-direction: column;
  }
`;

const NavElement = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  max-height: 47px;
  cursor: pointer;

  ${(props) =>
    props.color == "#2c6491"
      ? `background: ${props.color};color: white;`
      : `background: ${props.color};color: #222222;`}
`;

const NavElementText = styled.div`
  width: auto;
  padding: 14px 88px;

  text-align: center;
  @media (max-width: 420px) {
    padding: 14px 0;
  }
`;

const NavElementPointer = styled.div`
  width: 0;
  border-top: solid 10px #2c6491;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  z-index: 100;
  margin: auto;
  transform: translate(0, 1px);
`;

function Requirements() {
  const [active, setActive] = useState(1);
  const intl = useIntl();

  return (
    <Container>
      <BarTop>
        <NavBar>
          <NavElement
            color={active == 1 ? "#2c6491" : "#e6e6e6"}
            onClick={() => setActive(1)}
          >
            <NavElementText>
              {intl.formatMessage({
                id: "RequirementTitleOne",
              })}
            </NavElementText>
            {active == 1 && <NavElementPointer />}
          </NavElement>
          <NavElement
            color={active == 2 ? "#2c6491" : "#e6e6e6"}
            onClick={() => setActive(2)}
          >
            <NavElementText color={active == 2 ? "#2c6491" : "#e6e6e6"}>
              {intl.formatMessage({
                id: "RequirementTitleTwo",
              })}
            </NavElementText>
            {active == 2 && <NavElementPointer />}
          </NavElement>
          <NavElement
            color={active == 3 ? "#2c6491" : "#e6e6e6"}
            onClick={() => setActive(3)}
          >
            <NavElementText color={active == 3 ? "#2c6491" : "#e6e6e6"}>
              {intl.formatMessage({
                id: "RequirementTitleThree",
              })}
            </NavElementText>
            {active == 3 && <NavElementPointer />}
          </NavElement>
        </NavBar>
      </BarTop>
      <BarBottom>
        {active == 1 && (
          <BarBottomText>
            {intl.formatMessage({
              id: "RequirementParagprahOne",
            })}
          </BarBottomText>
        )}
        {active == 2 && (
          <BarBottomText>
            {intl.formatMessage({
              id: "RequirementParagprahTwo",
            })}
          </BarBottomText>
        )}
        {active == 3 && (
          <BarBottomText>
            {intl.formatMessage({
              id: "RequirementParagprahThree",
            })}
          </BarBottomText>
        )}
      </BarBottom>
    </Container>
  );
}

export default Requirements;
