import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import Title from "../shared/titles";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 520px;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  @media (max-width: 960px) {
    padding: 64px 0;
  }

  @media (max-width: 475px) {
    .av {
      width: 97px;
    }
    .emerson {
      width: 148px;
    }
    .microsoft {
      width: 148px;
    }
    .cisco {
      width: 104px;
    }
    .sheraton {
      width: 120px;
    }
    .hp {
      width: 148px;
    }
    .mma {
      width: 105px;
    }
    .oracle {
      width: 148px;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 30px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 40px;

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 198px;
  @media (max-width: 960px) {
    flex-direction: row;
    width: 100%;
  }
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const UnderTitle = styled.div`
  font-size: 18.2px;
  /* margin: 28px 0; */
  width: 100%;
  text-align: center;
`;

function Client() {
  const intl = useIntl();
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Row>
            <Title alignment="center">
              {intl.formatMessage({
                id: "ClientsTitle",
              })}
            </Title>
          </Row>
          <Row>
            <UnderTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "ClientsUnderTitle",
                  }),
                }}
              />
            </UnderTitle>
          </Row>
          <Row>
            <Column>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/av.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Av Logo"
                  className="av"
                />
              </ImgHolder>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/emerson.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Emerson Logo"
                  className="emerson"
                />
              </ImgHolder>
            </Column>
            <Column>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/microsoft.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Microsoft Logo"
                  className="microsoft"
                />
              </ImgHolder>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/cisco.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Cisco Logo"
                  className="cisco"
                />
              </ImgHolder>
            </Column>
            <Column>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/sheraton.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Sheraton Logo"
                  className="sheraton"
                />
              </ImgHolder>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/hp.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="HP Logo"
                  className="hp"
                />
              </ImgHolder>
            </Column>
            <Column>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/mma.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="MMA Logo"
                  className="mma"
                />
              </ImgHolder>
              <ImgHolder>
                <StaticImage
                  src="../../images/indexPageImages/clientImages/oracle.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                  alt="Oracle Logo"
                  className="oracle"
                />
              </ImgHolder>
            </Column>
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Client;
