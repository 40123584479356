import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import { StaticImage } from "gatsby-plugin-image";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1568px;

  @media (max-width: 1090px) {
    height: max-content;
    padding: 40px 0px;
  }

  .title0 {
    width: 486px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .title1 {
    width: 486px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .title2 {
    width: 486px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .img1 {
    width: 588px;
    height: 389px;

    @media (max-width: 1280px) {
      width: 488px;
      height: 389px;
    }
    @media (max-width: 1090px) {
      width: 588px;
      height: 389px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  .img2 {
    width: 626px;
    height: 426px;
    @media (max-width: 1280px) {
      width: 526px;
      height: 326px;
    }
    @media (max-width: 1090px) {
      width: 626px;
      height: 426px;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
  .img3 {
    width: 587px;
    height: 393px;
    @media (max-width: 1280px) {
      width: 487px;
      height: 293px;
    }
    @media (max-width: 1090px) {
      width: 587px;
      height: 393px;
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  gap: 90px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;

  @media (max-width: 1090px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  box-sizing: border-box;
  @media (max-width: 1090px) {
    align-items: center;
    order: 2;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1090px) {
    width: 100%;
  }
`;

const ImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1090px) {
    order: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

function Create() {
  return (
    <Container>
      <PositionContainer>
        <Column>
          <Row>
            <ImgBox>
              <StaticImage
                src="../../images/indexPageImages/createImages/create_img1.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Highlighted text in PDF Pro "
                className="img1"
              />
            </ImgBox>
            <Group>
              <Title margin="0px 0px 32px 0px" width="485px">
                Create a PDF from any application that prints
              </Title>
              <TextBox className="title0">
                <Paragraph>
                  PDF Writer allows you to create PDF files from any Windows
                  application that has a print function.
                </Paragraph>
                <Paragraph>
                  It installs itself as a "printer subsystem", which is a fancy
                  way of saying you can select PDF Writer as a printer.
                </Paragraph>
                <Paragraph>
                  Simply select PDF Writer from the choice of printers, click
                  print, and save your professional-quality PDF document. It's
                  that easy!
                </Paragraph>
              </TextBox>
            </Group>
          </Row>
          <Row>
            <Group>
              <Title margin="0px 0px 32px 0px" width="509px">
                Fill Forms, Sign, and Add Text Directly to a PDF
              </Title>
              <TextBox className="title1">
                <Paragraph>
                  PDF Writer reduces the needs trips to the scanner or printer
                  by allowing you to add text and signatures to a PDF document.
                </Paragraph>
                <Paragraph>
                  Fill PDF documents by adding and saving text directly within
                  your PDF files.
                </Paragraph>
                <Paragraph>
                  PDF Writer also gives you the power to create and save your
                  signature so you can quickly add it to any document requiring
                  one.
                </Paragraph>
                <Paragraph>
                  Write on and sign virtually any PDF file, save it, and
                  distribute as needed.
                </Paragraph>
              </TextBox>
            </Group>
            <ImgBox>
              <StaticImage
                src="../../images/indexPageImages/createImages/create_img2.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Adding a Signature to a document with PDF Pro"
                className="img2"
              />
            </ImgBox>
          </Row>
          <Row>
            <ImgBox>
              <StaticImage
                src="../../images/indexPageImages/createImages/create_img3.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF", "PNG"]}
                alt="Merging Files into PDF dialog box on PDF Pro"
                className="img3"
              />
            </ImgBox>
            <Group>
              <Title margin="0px 0px 32px 0px" width="418px">
                Edit, Merge &amp; Convert PDF files
              </Title>
              <TextBox className="title2">
                <Paragraph>
                  With PDF Writer you can merge multiple pages and documents
                  into a single PDF file. Just upload all the documents into a
                  list and the so!ware will create a single PDF document.
                </Paragraph>
                <Paragraph>
                  Our app comes equipped with many useful tools for reviewing
                  and marking up documents such as a highlighter, sticky notes,
                  stamps, attachments, and watermarks.
                </Paragraph>
                <Paragraph>
                  Convert PDF to Microsoft Word, Excel &amp; PowerPoint, saving
                  you time manually copying the content and giving you
                  additional editing power.
                </Paragraph>
              </TextBox>
            </Group>
          </Row>
        </Column>
      </PositionContainer>
    </Container>
  );
}

export default Create;
