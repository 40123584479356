import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
    width: ${({ instalWidth }) => (instalWidth ? "100%" : "327px")};
  }
  @media (max-width: 475px) {
    width: 100%;
  }
`;

const Header = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 384px;
  height: 41px;
  padding: 10px;
  background: #3571ce;

  @media (max-width: 1280px) {
    width: 330px;
  }

  @media (max-width: 1110px) {
    width: 280px;
  }
  @media (max-width: 960px) {
    width: 100%;
    padding: 0px;
  }
  @media (max-width: 768px) {
    width: ${({ instalWidth }) => (instalWidth ? "100%" : "327px")};
  }
  @media (max-width: 375px) {
    width: ${({ instalWidth }) => (instalWidth ? "100%" : "300px")};
  }
  //text CSS
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  //it has to have same width as header
  width: 384px;
  min-height: 138px;

  @media (max-width: 1280px) {
    width: 330px;
  }
  @media (max-width: 1110px) {
    width: 280px;
  }

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: ${({ instalWidth }) => (instalWidth ? "100%" : "327px")};
  }
  @media (max-width: 375px) {
    width: ${({ instalWidth }) => (instalWidth ? "100%" : "300px")};
  }
`;

function ContentBox({ title, children, instalWidth }) {
  return (
    <Holder instalWidth={instalWidth}>
      <Header instalWidth={instalWidth}>{title}</Header>
      <Box instalWidth={instalWidth}>{children}</Box>
    </Holder>
  );
}

ContentBox.propTypes = {
  title: PropTypes.string,
  instalWidth: PropTypes.bool,
};

ContentBox.defaultProps = {
  title: "",
  instalWidth: false,
};

export default ContentBox;
