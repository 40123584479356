import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
// import Icon1 from "../../images/indexPageImages/readImages/read_create_convert.svg";
// import Icon2 from "../../images/indexPageImages/readImages/read_edit_sign.svg";
// import Icon3 from "../../images/indexPageImages/readImages/read_read_review.svg";
import Icon1 from "../../images/indexPageImages/readImages/revision.svg";
import Icon2 from "../../images/indexPageImages/readImages/manipulate.svg";
import Icon3 from "../../images/indexPageImages/readImages/secure.svg";
import ContentBox from "../shared/contentBox";
import Paragraph from "../shared/paragraphs";
import Button from "../shared/buttons";
import Title from "../shared/titles";
import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 674px;
  background: #fbfbfb;
  @media (max-width: 960px) {
    height: max-content;
    padding: 40px 0px;
  }
  padding: 64px 0px;

  .icon {
    height: 114px;
    width: 84px;
  }
  .icon-different {
    height: 61px;
    width: 104px;
  }
`;
const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 63px;
`;
const SvgSizeCorrection = styled.div`
  width: ${(props) => props.size};
  height: auto;
  display: flex;
  justify-content: center;
  svg {
    overflow: visible !important;
  }
  #id1 {
    margin-right: 37.3%;
    transform: translate(0, -55px);
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: max-content;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 43px;
  }
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 31px;

  @media (max-width: 960px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 327px;
  }

  @media (max-width: 375px) {
    width: 300px;
  }
`;

const IconHolder = styled.div`
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  //width is irrelevant for this alignment
  width: 100%;
  //because that's the height of the biggest icon
  height: 114px;
`;

function Read() {
  const intl = useIntl();

  return (
    <Container>
      <PositionContainer>
        <ContentGroup>
          <Title>
            {intl.formatMessage({
              id: "FeaturesTitle",
            })}
          </Title>
          <Row>
            <Columns>
              <IconHolder>
                <SvgSizeCorrection size="96px">
                  <Icon1 className="icon-separate" />
                </SvgSizeCorrection>
              </IconHolder>
              <ContentBox
                title={intl.formatMessage({
                  id: "FeaturesBoxOneTitle",
                })}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: "FeaturesBoxOneParagraph",
                  })}
                </Paragraph>
                {/* <Paragraph>
                  Create PDF from MS Word, Excel &amp; PowerPoint
                </Paragraph>
                <Paragraph>Convert a PDF to MS Word or Excel</Paragraph>
                <Paragraph>
                  Merge multiple documents into a single PDF file
                </Paragraph> */}
              </ContentBox>
            </Columns>
            <Columns>
              <IconHolder>
                <SvgSizeCorrection size="100%">
                  <Icon2 className="icon" />
                </SvgSizeCorrection>
              </IconHolder>
              <ContentBox
                title={intl.formatMessage({
                  id: "FeaturesBoxTwoTitle",
                })}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: "FeaturesBoxTwoParagraph",
                  })}
                </Paragraph>
                {/* <Paragraph>Fill forms created in PDF format</Paragraph>
                <Paragraph>Sign documents that require signatures</Paragraph>
                <Paragraph>
                  Secure your PDF with restrictions &amp; passwords
                </Paragraph> */}
              </ContentBox>
            </Columns>
            <Columns>
              <IconHolder>
                <SvgSizeCorrection size="30%">
                  <Icon3 className="icon" />
                </SvgSizeCorrection>
              </IconHolder>
              <ContentBox
                title={intl.formatMessage({
                  id: "FeaturesBoxThreeTitle",
                })}
              >
                <Paragraph>
                  {intl.formatMessage({
                    id: "FeaturesBoxThreeParagraph",
                  })}
                </Paragraph>
                {/* <Paragraph>Markup, highlight and add comments</Paragraph>
                <Paragraph>
                  Use built-in stamps to approve or mark a PDF as paid or
                  overdue
                </Paragraph>
                <Paragraph>
                  View any PDF file, zoom, bookmark and rotate pages
                </Paragraph> */}
              </ContentBox>
            </Columns>
          </Row>
          <Button
            title={intl.formatMessage({
              id: "FeaturesFullList",
            })}
            navigation={`/${intl.formatMessage({
              id: "UrlFeatures",
            })}`}
            type="empty"
          ></Button>
        </ContentGroup>
      </PositionContainer>
    </Container>
  );
}

export default Read;
