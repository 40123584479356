import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import Title from "../shared/titles";
import Paragraph from "../shared/paragraphs";
// import Icon1 from "../../images/indexPageImages/chooseImages/choose_icon1.svg";
// import Icon2 from "../../images/indexPageImages/chooseImages/choose_icon2.svg";
// import Icon3 from "../../images/indexPageImages/chooseImages/choose_icon3.svg";
import Icon1 from "../../images/indexPageImages/chooseImages/price.svg";
import Icon2 from "../../images/indexPageImages/chooseImages/support.svg";
import Icon3 from "../../images/indexPageImages/chooseImages/lifetime.svg";
import Icon4 from "../../images/indexPageImages/chooseImages/customer_service.svg";
import { useIntl } from "gatsby-plugin-react-intl";

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #fbfbfb;
  width: 100%;
  height: auto;
  padding: 32px 0;

  .third-row {
    justify-content: space-between;
    width: 100%;
    gap: 24px;
  }

  @media (max-width: 960px) {
    height: max-content;
    padding: 40px 0px;
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const TextHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 792px;
  height: max-content;
  margin-top: 32px;

  @media (max-width: 960px) {
    width: 460px;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 22%;
  gap: 50px;
  margin-top: 60px;
  @media (max-width: 960px) {
    margin-top: 40px;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: max-content;
  width: 100%;

  /* @media (max-width: 1260px) {
    width: 300px;
  }

  @media (max-width: 1060px) {
    width: 280px;
  } */
  @media (max-width: 960px) {
    width: 460px;
  }
  @media (max-width: 560px) {
    width: 100%;
  }
`;

const ChooseTitle = styled.h3`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: max-content;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
  text-transform: uppercase;
  color: #111111;
  text-align: center;
  min-height: 56px;
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: 110px;
  min-height: 123px;
`;

function Choose() {
  const intl = useIntl();

  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <Row>
            <Title alignment="center">
              {intl.formatMessage({
                id: "ChooseTitle",
              })}
            </Title>
          </Row>
          <Row>
            <TextHolder>
              <Paragraph type="center">
                {intl.formatMessage({
                  id: "ChooseParagraph",
                })}
              </Paragraph>
            </TextHolder>
          </Row>
          <Row className="third-row">
            <Column>
              <Box>
                <ImgHolder>
                  <Icon1 />
                </ImgHolder>
              </Box>
              <Box>
                <ChooseTitle>
                  {intl.formatMessage({
                    id: "ChoseBoxOneTitle",
                  })}
                </ChooseTitle>
                <Paragraph type="center">
                  {intl.formatMessage({
                    id: "ChoseBoxOneParagraph",
                  })}
                </Paragraph>
              </Box>
            </Column>
            <Column>
              <Box>
                <ImgHolder>
                  <Icon2 />
                </ImgHolder>
              </Box>
              <Box>
                <ChooseTitle>
                  {intl.formatMessage({
                    id: "ChoseBoxTwoTitle",
                  })}
                </ChooseTitle>
                <Paragraph type="center">
                  {intl.formatMessage({
                    id: "ChoseBoxTwoParagraph",
                  })}
                </Paragraph>
              </Box>
            </Column>
            <Column>
              <Box>
                <ImgHolder>
                  <Icon3 />
                </ImgHolder>
              </Box>
              <Box>
                <ChooseTitle>
                  {intl.formatMessage({
                    id: "ChoseBoxThreeTitle",
                  })}
                </ChooseTitle>
                <Paragraph type="center">
                  {intl.formatMessage({
                    id: "ChoseBoxThreeParagraph",
                  })}
                </Paragraph>
              </Box>
            </Column>
            <Column>
              <Box>
                <ImgHolder>
                  <Icon4 />
                </ImgHolder>
              </Box>
              <Box>
                <ChooseTitle>
                  {intl.formatMessage({
                    id: "ChoseBoxFourTitle",
                  })}
                </ChooseTitle>
                <Paragraph type="center">
                  {intl.formatMessage({
                    id: "ChoseBoxFourParagraph",
                  })}
                </Paragraph>
              </Box>
            </Column>
          </Row>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Choose;
